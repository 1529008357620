@use 'scss/text';
@use 'scss/border';
@import '../LeaderboardDetailsMixins.module.scss';

.container {
  @include list-grid;

  height: 100%;
  width: 100%;
  // max-height: 52px;
  max-height: 60px;
  // border-radius: 8px;
  padding: 14px 0px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);

  @media (min-width: 768px) {
    padding: 14px;
  }
  // @media (min-width: 1800px) {
  //   max-height: 88px;
  // }

  &.highlightSearch {
    background-color: rgba(229, 229, 229, 1);
    p {
      color: black;
    }
  }
  &.highlightPlayer {
    background-color: rgba(229, 229, 229, 1);
    p {
      color: black !important;
    }
  }

  .rank {
    font-size: 16px;
    @include lb-responsive-text-14;
    align-self: center;
    padding-left: 4px;
    // color: var(--toca-theme-white);
    // text-shadow: 0px -1px 0px #f7921d, 1px 2px 0px #f7921d;

    // @media (min-width: 1800px) {
    //   font-size: 40px;
    // }

    &.longRank {
      font-size: 18px;
      padding-left: 0px;

      // @media (min-width: 1800px) {
      //   font-size: 32px;
      // }
    }
  }

  .player {
    font-size: 16px;
    @include lb-responsive-text-14;
    display: flex;
    align-items: center;
    min-width: 0;

    .nameAndLocation {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .center {
        align-self: flex-start;
        text-align: left;
        @include text.ellipsis;
        line-height: 12px;
        opacity: 0.7;
      }
    }

    .name {
      @include text.ellipsis;
      flex: 1;
    }

    .iconContainer {
      min-width: 28px;
      width: 28px;
      height: 28px;
      margin-right: 16px;
      overflow: hidden;
      display: flex;
      position: relative;
      transform: rotate(45deg);
      border: 1px solid rgba(0, 0, 0, 0.2);

      @media (min-width: 768px) {
        min-width: 32px;
        height: 32px;
        width: 32px;
      }
      // @media (min-width: 1800px) {
      //   height: 64px;
      //   width: 64px;
      //   margin-right: 24px;
      // }

      .iconTransform {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: rotate(-45deg) scale(1.2);

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;

          &.placeholder {
            object-fit: contain;
            max-width: 80%;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .score,
  .speed {
    font-size: 16px;
    @include lb-responsive-text-14;
    align-self: center;
    text-align: right;
  }

  .speed {
    font-size: 16px;
    @include lb-responsive-text-14;
    span {
      color: var(--theme-grey);
    }
  }
}
