@mixin slight-black {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

@mixin dispersed-black {
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.1);
}

@mixin dispersed-black-bottom {
  box-shadow: 0px 24px 24px -24px rgba(0, 0, 0, 0.1);
}
