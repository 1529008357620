@use 'scss/text';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  p.info {
    @include text.normal-black(14px);

    span {
      @include text.semibold-black(14px);
    }
  }

  .bottomContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    p {
      @include text.normal-grey(13px);
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
