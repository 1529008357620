@use 'scss/text';
@use 'scss/animation';

.flexContainer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  section {
    flex: 1;
    @include animation.fadeIn(0.15s);
  }
}

.viewToggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .toggleButton {
    @include text.medium-black(18px);
    padding: 8px 16px;
    border-radius: 9999px;
    transition: 0s;

    &.active {
      transition: 0.2s;
      color: white;
      background-color: var(--theme-secondary-alt);
    }

    &:disabled {
      color: var(--theme-grey);
      cursor: not-allowed;
    }
  }
}
