@use 'scss/text';
@use 'scss/border';

.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  p.info {
    @include text.normal-black(14px);

    span {
      @include text.semibold-black(14px);
    }
  }

  p.error,
  p.success {
    display: block;
    margin: 0 16px;
    text-align: center;
  }
  p.success {
    @include text.normal-primary(13px);
  }
  p.error {
    @include text.normal-alert(13px);
  }

  .bottomContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    p {
      @include text.normal-grey(13px);
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
