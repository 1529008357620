@use 'scss/text';

.container {
  display: flex;
  justify-content: center;

  p {
    @include text.semibold-primary(14px);
    padding: 8px;
  }
}
