@use 'scss/text';
@use 'scss/border';
@import '../LeaderboardDetailsMixins.module.scss';

.container {
  background-color: var(--theme-black);
  border-radius: 12px;
  position: relative;
  text-align: center;
  overflow: hidden;
  transition: 0.2s;

  @media (min-width: 768px) {
    padding: 0 12px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    padding: 0 24px;
  }

  .collapsedContent {
    @include border.thin-grey-bottom-dark;
    height: 64px;
    padding-right: 24px;

    // @media (min-width: 1800px) {
    //   height: 104px;
    //   padding: 24px 12px 24px 0;
    // }

    h1 {
      @include text.semibold-white(14px);
      @include text.text-ellipsis;
      @include lb-responsive-text-14;
      text-transform: uppercase;
      text-align: left;
      line-height: 64px;
      padding: 0 12px;
      margin-left: 12px;
    }

    .toggle {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      // @media (min-width: 1800px) {
      //   height: 104px;
      //   padding: 24px 12px;
      // }
      .adornment {
        padding: 16px;
        transition: 0.15s;

        &.isOpen {
          transform: rotate(180deg);
        }

        @media (max-width: 350px) {
          padding: 6px;
        }
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  .expandedContent {
    padding: 12px 12px 24px;

    p {
      @include text.normal-white(14px);

      span {
        @include text.semibold-white(14px);
      }

      &.metric {
        text-transform: capitalize;
      }
    }

    & :not(:last-child) {
      margin-bottom: 16px;
    }

    @media (min-width: 768px) {
      p.description {
        font-size: 14px;
      }
    }
    // @media (min-width: 1800px) {
    //   padding: 0 12px 36px;

    //   p,
    //   p.description,
    //   span {
    //     font-size: 24px;
    //   }
    //   & :not(:last-child) {
    //     margin-bottom: 36px;
    //   }
    // }
  }
}
