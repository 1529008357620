// These sass variables are extended downwards with the intention
// of only exporting the resulting Mixins from this page.

// Core Font-Families
%body-font {
  font-family: 'Poppins', sans-serif;
}
%heading-font {
  font-family: 'Roboto Condensed', sans-serif;
}
%subheading-font {
  font-family: 'Poppins', sans-serif;
}

// Font & Weight Combinations
// body
%body-normal {
  @extend %body-font;
  font-weight: normal;
}
%body-medium {
  @extend %body-font;
  font-weight: 500;
}
%body-semibold {
  @extend %body-font;
  font-weight: 600;
}
%body-bold {
  @extend %body-font;
  font-weight: 700;
}
// subheading
%subheading-bold {
  @extend %subheading-font;
  font-weight: 700;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
// heading
%heading-normal {
  @extend %heading-font;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-transform: uppercase;
}

// Line-height is 20px unless the font is larger
@mixin lineHeight($fontSize) {
  @if $fontSize <= 20 {
    line-height: 20px;
  }
}

// Mixins for actual use. Fixed colors, variable sizes.
// normal
@mixin normal-white($size) {
  @extend %body-normal;
  @include lineHeight($size);
  color: var(--theme-text-white);
  font-size: $size;
}
@mixin normal-grey($size) {
  @extend %body-normal;
  @include lineHeight($size);
  color: var(--theme-text-grey);
  font-size: $size;
}
@mixin normal-black($size) {
  @extend %body-normal;
  @include lineHeight($size);
  color: var(--theme-text-black);
  font-size: $size;
}
@mixin normal-primary($size) {
  @extend %body-normal;
  @include lineHeight($size);
  color: var(--theme-text-primary);
  font-size: $size;
}
@mixin normal-alert($size) {
  @extend %body-normal;
  @include lineHeight($size);
  color: var(--theme-alert);
  font-size: $size;
}
// medium
@mixin medium-white($size) {
  @extend %body-medium;
  @include lineHeight($size);
  color: var(--theme-text-white);
  font-size: $size;
}
@mixin medium-grey($size) {
  @extend %body-medium;
  @include lineHeight($size);
  color: var(--theme-text-grey);
  font-size: $size;
}
@mixin medium-black($size) {
  @extend %body-medium;
  @include lineHeight($size);
  color: var(--theme-text-black);
  font-size: $size;
}
@mixin medium-primary($size) {
  @extend %body-medium;
  @include lineHeight($size);
  color: var(--theme-text-primary);
  font-size: $size;
}
@mixin medium-blue($size) {
  @extend %body-medium;
  @include lineHeight($size);
  color: var(--theme-blue);
  font-size: $size;
}
@mixin medium-alert($size) {
  @extend %body-medium;
  @include lineHeight($size);
  color: var(--theme-alert);
  font-size: $size;
}
// semibold
@mixin semibold-white($size) {
  @extend %body-semibold;
  @include lineHeight($size);
  color: var(--theme-text-white);
  font-size: $size;
}
@mixin semibold-grey($size) {
  @extend %body-semibold;
  @include lineHeight($size);
  color: var(--theme-text-grey);
  font-size: $size;
}
@mixin semibold-black($size) {
  @extend %body-semibold;
  @include lineHeight($size);
  color: var(--theme-text-black);
  font-size: $size;
}
@mixin semibold-primary($size) {
  @extend %body-semibold;
  @include lineHeight($size);
  color: var(--theme-text-primary);
  font-size: $size;
}
// bold
@mixin bold-white($size) {
  @extend %body-bold;
  @include lineHeight($size);
  color: var(--theme-text-white);
  font-size: $size;
}
@mixin bold-grey($size) {
  @extend %body-bold;
  @include lineHeight($size);
  color: var(--theme-text-grey);
  font-size: $size;
}
@mixin bold-black($size) {
  @extend %body-bold;
  @include lineHeight($size);
  color: var(--theme-text-black);
  font-size: $size;
}
@mixin bold-primary($size) {
  @extend %body-bold;
  @include lineHeight($size);
  color: var(--theme-text-primary);
  font-size: $size;
}
@mixin bold-blue($size) {
  @extend %body-bold;
  @include lineHeight($size);
  color: var(--theme-blue);
  font-size: $size;
}
@mixin bold-blue-light($size) {
  @extend %body-bold;
  @include lineHeight($size);
  color: var(--theme-blue-light);
  font-size: $size;
}
@mixin bold-alert($size) {
  @extend %body-bold;
  @include lineHeight($size);
  color: var(--theme-alert);
  font-size: $size;
}
// subheading
@mixin subheading-white($size) {
  @extend %subheading-bold;
  @include lineHeight($size);
  color: var(--theme-text-white);
  font-size: $size;
}
@mixin subheading-grey($size) {
  @extend %subheading-bold;
  @include lineHeight($size);
  color: var(--theme-text-grey);
  font-size: $size;
}
@mixin subheading-primary($size) {
  @extend %subheading-bold;
  @include lineHeight($size);
  color: var(--theme-text-primary);
  font-size: $size;
}
@mixin subheading-blue($size) {
  @extend %subheading-bold;
  @include lineHeight($size);
  color: var(--theme-text-blue);
  font-size: $size;
}
@mixin subheading-alert($size) {
  @extend %subheading-bold;
  @include lineHeight($size);
  color: var(--theme-alert);
  font-size: $size;
}
// heading
@mixin heading-white($size) {
  @extend %heading-normal;
  @include lineHeight($size);
  color: var(--theme-text-white);
  font-size: $size;
}
@mixin heading-grey($size) {
  @extend %heading-normal;
  @include lineHeight($size);
  color: var(--theme-text-grey);
  font-size: $size;
}
@mixin heading-black($size) {
  @extend %heading-normal;
  @include lineHeight($size);
  color: var(--theme-text-black);
  font-size: $size;
}
@mixin heading-primary($size) {
  @extend %heading-normal;
  @include lineHeight($size);
  color: var(--theme-text-primary);
  font-size: $size;
}
@mixin heading-blue($size) {
  @extend %heading-normal;
  @include lineHeight($size);
  color: var(--theme-blue);
  font-size: $size;
}
@mixin heading-alert($size) {
  @extend %heading-normal;
  @include lineHeight($size);
  color: var(--theme-alert);
  font-size: $size;
}
//numeric
@mixin numeric-grey-light($size) {
  @extend %subheading-bold;
  @include lineHeight($size);
  color: var(--theme-text-lightgrey);
  font-size: $size;
  font-weight: 600;
  letter-spacing: normal;
}
@mixin numeric-primary($size) {
  @extend %subheading-bold;
  @include lineHeight($size);
  color: var(--theme-text-primary);
  font-size: $size;
  font-weight: 600;
  letter-spacing: normal;
}

// //

// //
// old styles
// //
@mixin font-poppins($size) {
  font-family: 'Poppins', sans-serif;
  font-size: $size;
}

@mixin font-poppins-light($size) {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: $size;
}

@mixin font-poppins-bold($size) {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: $size;
}

@mixin font-helvetica($size) {
  font-family: 'Helvetica', sans-serif;
  font-size: $size;
}

@mixin font-helvetica-light($size) {
  font-family: 'Helvetica', sans-serif;
  font-weight: 300;
  font-size: $size;
}

@mixin font-helvetica-bold($size) {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: $size;
}
@mixin font-helvetica-superbold($size) {
  font-family: 'Helvetica', sans-serif;
  font-weight: 600;
  font-size: $size;
}

@mixin font-helvetica-heading($size) {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: $size;
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
