@use 'scss/text';
@use 'scss/border';

.tableContainer {
  overflow: scroll;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    @include border.table-grey-dark;
    text-align: center;
    padding: 4px;
    width: 10ch;
    min-width: 10ch;
    max-width: 10ch;
  }

  th {
    @include text.bold-black(10px);
    text-transform: uppercase;
  }
  td {
    @include text.semibold-grey(11px);
  }

  th.fullWidth,
  td.fullWidth {
    width: 100%;
    min-width: 28ch;
    max-width: 100%;
    text-align: start;
  }

  td.fullWidth {
    @include text.semibold-grey(11px);
  }

  tr.blankRow {
    height: 8px;
    border: none;
  }

  .borderlessCell {
    border-left: none;
    border-right: none;
  }

  .playerNameCell {
    position: relative;
    p {
      position: relative;
      margin-left: -100%;
      width: 300%;
    }
  }
}

.levelHeading {
  p {
    padding-left: 8px;
  }

  &.beginner {
    @include text.heading-black(18px);
    background-color: var(--theme-level-beginner);
  }
  &.intermediate {
    @include text.heading-black(18px);
    background-color: var(--theme-level-intermediate);
  }
  &.advanced {
    @include text.heading-white(18px);
    background-color: var(--theme-level-advanced);
  }
  &.pro {
    @include text.heading-white(18px);
    background-color: var(--theme-level-pro);
  }
}
