@use 'scss/text';

.container {
  position: sticky;
  bottom: -1px;
  left: 0;
  height: 70px;
  max-width: var(--sitewide-max-width);
  background-color: var(--theme-primary);
  z-index: 10;
  display: flex;
  justify-content: center;
}
// .container {
//   position: fixed;
//   bottom: -1px;
//   left: 50%;
//   transform: translateX(-50%);
//   height: 70px;
//   width: 100%;
//   max-width: var(--sitewide-max-width);
//   background-color: var(--theme-primary);
//   z-index: 10;
//   display: flex;
//   justify-content: center;
// }

.buttonContainer {
  display: flex;
  width: 100%;
  max-width: 600px;
}

.navButton {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    height: 32px;
    width: 32px;
    display: grid;
    place-content: center;

    svg {
      width: auto; // TODO STYLES
      height: auto;
      max-width: 32px;
      max-height: 32px;
      fill: var(--theme-white);
      transition: fill 0.2s;
    }
  }

  .text {
    @include text.normal-grey(13px);
    color: var(--theme-bluegrey); // TODO STYLES one-off color override
    margin-top: 4px;

    @media (max-width: 300px) {
      display: none;
    }
  }

  &.active {
    .textContainer {
      color: var(--theme-white);
    }
  }
}
