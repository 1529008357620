@use 'scss/shadow';
@import '../../Leaderboards/LeaderboardDetails/LeaderboardDetailsMixins.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inputWrapper {
  margin-bottom: 16px;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100000; // modal is 99,999
  background-color: rgba(14, 21, 43, 0.6);
  backdrop-filter: blur(3px);

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 0.1s;
  display: grid;
  place-content: center;
}
