@use 'scss/text';
@use 'scss/border';

.input {
  @include border.thin-transparent;
  @include text.normal-grey(13px);

  width: 100%;
  display: flex;
  height: 48px;
  padding: 12px 16px;
  background-color: var(--theme-grey-xlight);
  transition: all 0.1s;

  &.new {
    font-size: 15px;
    background-color: var(--theme-white);
    border: 1px solid var(--theme-grey-light);
  }

  &:focus {
    @include border.thin-primary;
  }

  &:disabled {
    @include border.thin-grey-dark;
    background-color: var(--theme-white);
  }

  &.errorState {
    @include border.thin-alert;
    @include text.normal-alert(13px);
  }
}
