@use 'scss/text';
@use 'scss/border';

.container {
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    @include border.table-grey-dark;
    text-align: center;
    padding: 4px;
  }
}
