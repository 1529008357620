@use 'scss/text';
@use 'scss/border';
@use 'scss/animation';

.container,
.container ul {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.profileWrapper {
  @include border.thin-grey-light;
  display: flex;
  padding: 16px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .profile {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    min-width: 0;
    @media (min-width: 768px) {
      width: 75%;
    }

    .flexRow {
      display: inline-flex;
      align-items: center;
      column-gap: 12px;
      margin-bottom: 8px;
    }

    p {
      @include text.normal-black(12px);
      @include text.ellipsis;
      line-height: 20px;

      &.name {
        @include text.bold-black(16px);
      }

      // TODO STYLES confirm appearance
      &.inactiveLabel {
        color: #f35d1b;
      }
    }
  }
}

.subheading {
  @include text.subheading-grey(10px);
  text-transform: uppercase;
  text-align: center;
  margin-top: 12px;
}

.cardContainer {
  cursor: pointer;
}

.buttonContainer {
  // @include animation.fadeDrop(0.3s);
  margin-top: 24px;
  display: flex;
  justify-content: center;
  column-gap: 24px;
}
