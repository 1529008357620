.mixed-chart {
  padding: 0;
  width: 100%;
}

// used in FKO
.modal-fixed-max-width {
  .modal-backdrop {
    max-width: 600px;
    margin: 0 auto;
  }
}

body.embedded {
  background-color: #fff;

  .App {
    box-shadow: none;

    // TODO needed?
    max-width: 600px;
    margin: 0 auto;
  }

  .toca-logo {
    display: none;
  }

  .loader-container {
    .toca-logo {
      display: flex !important;
    }
  }

  .modal-backdrop.fullscreen {
    align-items: flex-start;
  }
  .modal-content-fullscreen {
    border-radius: 0;
  }
}
