@use 'scss/text';
@import '../../../../Leaderboards/LeaderboardDetails/LeaderboardDetailsMixins.module.scss';

.container {
  border-bottom: 1px solid var(--theme-grey-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
}

.playerInfo {
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    @include text.font-poppins-bold(14px);
    @include text.text-ellipsis;

    &.name {
      text-transform: capitalize;
    }
    &.email {
      color: var(--theme-text-grey);
    }
  }
}
