.container {
  position: fixed;
  bottom: 70px;
  left: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobileWrapper {
    position: relative;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .closeButton {
      position: absolute;
      top: 18px;
      right: 18px;

      svg {
        height: 28px;
        width: 28px;
      }
    }

    .content {
      flex: 1;
      padding: 30px 24px 24px;
      border-radius: 12px 12px 0 0;
      background-color: var(--toca-theme-offwhite);
      // border: 1px solid var(--toca-theme-grey6);

      height: 0px;
      max-height: 104px;
      transition: 0.2s;
    }

    &.open {
      .content {
        height: auto;
        max-height: 80vh;
      }
    }
  }
}
