.waiverContainer {
  font-size: 16px;
  margin-top: 50px;

  p {
    margin-bottom: 1rem;
  }
}

.textHighlight {
  color: #ffac12;
}

.waiverModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 176px);
  background: #fff;
  z-index: 99999; // must be high in order to overlay the global header
}

.errorMessage {
  display: flex;
  justify-content: center;

  p {
    text-align: center;
  }
}
